import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '../block-content'
import InfoProjects from './info'
import InfoMobile from './info-mobile'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'

const projectsHome = ({ info, texts }) => {
  return (
    <div className='home-projects grid'>
      <h1 data-sal='slide-down' data-sal-delay='200' data-sal-duration='800' data-sal-easing='ease-out'>
        <BlockContent blocks={texts._rawText || []}/>
      </h1>
      <InfoProjects info={info}/>
      <InfoMobile info={info}/>
      <h1 data-sal='slide-down' data-sal-delay='200' data-sal-duration='800' data-sal-easing='ease-out' className='bottom-title'>
        <BlockContent blocks={texts._rawBottomText || []}/>
      </h1>
      <Link data-sal='slide-down' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out' id='cta-about' className='cta' to='/about'>About Arquetipo <Arrow /></Link>
    </div>
  )
}

export default projectsHome
