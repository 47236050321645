import React, { useState } from 'react'
import { Link } from 'gatsby'
import Image from '../image'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'

const InfoProjects = ({ info }) => {
  const
    projects = info.edges,
    [visible, setVisible] = useState(null);

  return (
    <React.Fragment>
      <div className='text-container desktop'>
        <p className='recent' data-sal='fade' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out'>Recent projects</p>
        <ul className='list'>
          {projects.map((el, i) => {
            const project = el.node
            return (
              <Link to={`/projects/${project.slug.current}`} key={`${i}-${new Date().getTime()}`}>
                <li onMouseEnter={() => setVisible(i)} onMouseLeave={() => setVisible(null)}>{project.title}</li>
              </Link>
            )
          })}
        </ul>
        <Link data-sal='fade' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out' to='/projects' id='cta-home-projects' className='cta'>View all projects <Arrow /></Link>
      </div>
      <div className='image-container desktop'>
        <Image className={visible === 0 ? 'visible' : undefined} fluid={projects[0].node.outsideCover.asset.fluid} durationFadeIn={100}/>
        <Image className={visible === 1 ? 'visible' : undefined} fluid={projects[1].node.outsideCover.asset.fluid} durationFadeIn={100}/>
        <Image className={visible === 2 ? 'visible' : undefined} fluid={projects[2].node.outsideCover.asset.fluid} durationFadeIn={100}/>
        <Image className={visible === 3 ? 'visible' : undefined} fluid={projects[3].node.outsideCover.asset.fluid} durationFadeIn={100}/>
        {visible !== null &&
          <p>{projects[visible].node.title} - {projects[visible].node.firstRow.info.year}</p>
        }
      </div>
    </React.Fragment>
  )
}

export default InfoProjects
