import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/layout/headerHome'
import Footer from '../components/layout/footer'
import SEO from '../components/seo'
import Hero from '../components/home/hero'
import Projects from '../components/home/projects'

export const query = graphql`
query IndexQuery {
  home: sanityHome {
    hero {
      _rawText
    }
    projects {
      _rawText
      _rawBottomText
    }
  }
  info: allSanityProject(sort: {fields: _createdAt, order: DESC}, limit: 4) {
    edges {
      node {
        title
        slug {
          current
        }
        outsideCover {
          asset {
            fluid(maxWidth: 1100) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        firstRow {
          info {
            year
          }
        }
      }
    }
  }
}
`

const IndexPage = ({data}) => {
  const
    { home, info } = data,
    { hero, projects } = home;
  return(
    <>
      <Header/>
        <SEO title='Home'/>
        <Hero text={ hero._rawText }/>
        <Projects texts={ projects } info={ info }/>
      <Footer/>
    </>
  )
}


export default IndexPage
