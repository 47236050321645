import React, { useEffect, useRef } from 'react'
import hoverEffect from 'hover-effect'
import Img1 from '../../assets/images/home-man-in-space.jpg'
import Img2 from '../../assets/images/arquetipo-splash1.jpg'
import displacement from '../../assets/images/map-9.jpg'
import BlockContent from '../block-content'

const Hero = ({ text }) => {
  const img = useRef(null)

  useEffect(() => {
    const effect = new hoverEffect({
        parent: img.current,
        intensity: 0.03,
        imagesRatio: 1547 / 2560,
        image1: Img1,
        image2: Img2,
        displacementImage: displacement
    })
  }, [])

  return(
    <div className='hero-container'>
      <div ref={img} className='gatsby-image-wrapper selectDisable' data-sal='fade' data-sal-delay='200' data-sal-duration='700' data-sal-easing='ease'></div>
      <div className='texts grid selectDisable'>
        <h1 data-sal='slide-down' data-sal-delay='200' data-sal-duration='800' data-sal-easing='ease-out'>
          <BlockContent className='hero-title' blocks={text || []}/>
        </h1>
      </div>
    </div>
  )
}

export default Hero
