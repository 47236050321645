import React from 'react'
import { Link } from 'gatsby'
import Image from '../image'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'

function SingleProjectMobile({ project }) {
  return (
    <div data-sal='fade' data-sal-duration='800' data-sal-easing='ease-out' className="project-mobile">
      <Image fluid={project.outsideCover.asset.fluid} alt={project.title}/>
      <Link className='cta' to={`/projects/${project.slug.current}`}>{project.title}<Arrow/></Link>
    </div>
  )
}

const InfoMobile =({ info: { edges }}) => (
  <>
    <p className='recent mobile'>Recent projects</p>
    {edges.map((project, i) => {
      return(
        <SingleProjectMobile key={i * 8845} project={project.node}/>
      )
    })}
    <Link data-sal='slide-down' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out' to='/projects' id='cta-home-projects' className='cta mobile'>View all projects <Arrow /></Link>
  </>
)

export default InfoMobile
