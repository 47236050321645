import React from 'react'
import Menu from './menu'
import Logo from '../../assets/svg/arquetipo-logo.svg'

const Header = () => (
  <header>
      <a href="https://space.arquetipoestudio.com" target='__blank' data-sal="fade" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
        <Logo className='logo'/>
      </a>
    <Menu/>
  </header>
)

export default Header
